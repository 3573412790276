<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="8" lg="8">
        <h1 class="text-center mb-2">{{this.$store.state.client_settings.APPNAME}}</h1>
        <h3 class="text-center mb-4">Recupera Password </h3>
        <p class="grey--text">
          Indicando la tua mail, verrà avviato il processo di reset della password.
          Se la tua mail è presente nel nostro database, ti saranno inviate le istruzioni per procedere alla scelta di una nuova password.
        </p>
        <v-card class="mx-auto text-center" v-show="show_success_box">
          <v-card-text>
            <p class="display-1 text--primary">Ben Fatto!</p>
            <p>Abbiamo preso in carico la tua richiesta</p>
            <v-btn class="ma-2" color="success" to="/">
              Vai alla Home
              <v-icon dark right>mdi-home</v-icon>
            </v-btn>
            <div class="caption mt-4">
              Riceverai a breve una mail con le istruzoni per proseguire..
              <br />Se non la trovi nella tua casella tra qualche minuto controlla la posta indesiderata o spam
            </div>
          </v-card-text>
        </v-card>
        <v-form ref="form" v-model="valid" :lazy-validation="lazy" v-show="show_form">
          <v-text-field
            outlined
            v-model="email"
            :append-icon="'mdi-email-outline'"
            :rules="[rules.email]"
            label="E-mail"
            required
          ></v-text-field>
          <MjRecaptcha ref="recaptcha" :captchaToken.sync="captchaToken" />
          <v-btn
            :disabled="!valid || recupera_pwd_btn_loading"
            large
            color="primary"
            block
            class="mr-4"
            :loading="recupera_pwd_btn_loading"
            @click="submit"
          >Recupera Password</v-btn>
          <v-alert v-if="recupero_error_show" type="warning" class="mt-2 py-2 warning">
            <span v-text="recupero_error_text"></span>
          </v-alert>
          <hr class="mt-5 ml-3 mr-3" />
          <div class="mt-4 text-center">
            <router-link to="/utente/registrati">Registrati per creare un account</router-link>
          </div>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MjRecaptcha from "@/components/form/MjRecaptcha.vue";
import mj_axios from "@/mj_axios.js";
import ValidationRules from "@/mixins/ValidationRulesMixin.js"

export default {
  name: "RecoverPasswordView",
  //definiamo i componenti che desideriamo utilizzare in questo componente per evitare che venga creato un js troppo grande nella build
  components: { MjRecaptcha },
  mixins:[ValidationRules],
  data: () => ({
    valid: true,
    recupera_pwd_btn_loading: false,
    captchaToken: null,
    show_success_box: false,
    show_form: true,
    recupero_error_show: false,
    recupero_error_text: "",
    email: "",

    lazy: false
  }),

  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        //this.snackbar = true;
        if (this.captchaToken == null) {
          this.recupero_error_show = true;
          this.recupero_error_text = "Clicca sul Captcha per Proseguire!";
        } else {
          this.recupera_pwd_btn_loading = true;

          const params = {
            email: this.email,
            g_recaptcha_response: this.captchaToken
          };

          mj_axios
            .post("/api/user/password/init_reset", params)
            .then(() => {

              // response;
              // const reset_pwd_code = response.data;
              // const clientSettings = window.localStorage.getItem("ClientSettings");
              // console.log(clientSettings);
              this.show_success_box = true;
              this.show_form = false;
              // switch (reset_pwd_code) {
              //   case 0:
              //     this.show_form = false;
              //     this.show_success_box = true;
              //     break;
              //   case captchaEnum.CODE_NO_CAPTCHA_SENT:
              //     this.registration_error_show = true;
              //     this.registration_error_text = "Captcha non inviato, se il problema persiste scrivi a <a href='mailto:<?php echo $MAIL_ASSISTENZA_TECNICA; ?>'><?php echo $MAIL_ASSISTENZA_TECNICA; ?></a>"; //TODO variabile globale email assistenza
              //     break;
              //   case captchaEnum.CODE_CAPTCHA_VERIFY_FAILED:
              //     this.registration_error_show = true;
              //     this.registration_error_text = "Captcha non valido o scaduto, riprova, se il problema persiste scrivi a <a href='mailto:<?php echo $MAIL_ASSISTENZA_TECNICA; ?>'><?php echo $MAIL_ASSISTENZA_TECNICA; ?></a>"; //TODO variabile globale email assistenza
              //     break;
              //   default:
              //     this.registration_error_show = true;
              //     this.registration_error_text = "Errore Generico, riprova, se l'errore persiste scrivi a <a href='mailto:<?php echo $MAIL_ASSISTENZA_TECNICA; ?>'><?php echo $MAIL_ASSISTENZA_TECNICA; ?></a>"; //TODO variabile globale email assistenza
              //     break;
              // }
            })
            .catch(e => {
              this.recupero_error_show = true;

              this.recupero_error_text = e.response.data.message;
            })
            .then(() => {
              // always executed
              this.recupera_pwd_btn_loading = false;
            });
        }
      }
    },
    // resetCaptcha(){this.$refs.recaptcha.resetRecaptcha()},
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  },
  watch: {
    email: {
      handler: function() {

        this.recupero_error_show = false;
        this.recupero_error_text = "";
        return true;
      }
    }
  }
};
</script>

<template>
  <v-container>
    <v-row>
      <v-col class="grow">
        <v-btn rounded depressed :to="{name:'NewScheda'+resourceName}">
          <v-icon color="primary">mdi-plus</v-icon>
          Aggiungi {{item_meta_data.item_name}}
        </v-btn>
      </v-col>
      <v-col class="shrink">
        <v-btn @click="loadItems" icon>
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-list two-line flat>
      <v-subheader>Lista {{item_meta_data.item_plural_name}}</v-subheader>
      <transition name="fade">
        <v-progress-linear indeterminate rounded height="16" v-if="showLoader"></v-progress-linear>
      </transition>
      <v-alert prominent v-if="error_show" type="warning" class="mt-2 py-2 warning">
        <v-row align="center">
          <v-col class="grow" v-text="error_text"></v-col>
          <v-col class="shrink">
            <v-btn @click="loadItems">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
      <v-list-item-group v-model="item">
        <v-list-item v-if="emptyList">
          <v-list-item-icon>
            <v-icon>mdi-information-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Nessun Dato Disponibile</v-list-item-title>
            <v-list-item-subtitle>Inserisci dati nuovi e ricarica</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="emptyList"></v-divider>

        <template v-for="(item,i) in items">
          <v-divider v-if="(i>0)" :key="`divider-${i}`"></v-divider>

          <v-list-item :key="item.id" :to="{name:'Scheda'+resourceName,params:{id:item.id}}">
            <v-list-item-icon>
              <v-icon>{{item_meta_data.icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
              <v-list-item-subtitle v-text="item.sub_title"></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action
              v-if="item_meta_data.is_erasable"
              @click.prevent.stop="deleteItem(item,i)"
            >
              <v-btn icon>
                <v-icon color="grey lighten-1" v-show="!item.deleting">mdi-delete</v-icon>
                <v-progress-circular v-show="item.deleting" indeterminate color="primary"></v-progress-circular>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </v-container>
</template>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<script>
import mj_axios from "@/mj_axios.js";
import mj_string_utils from "@/misc/mj_string_utils.js"

export default {
  name: "ListaItems",
  data: () => ({
    item: 1,
    items: [],
    emptyList: false,
    showLoader: false,
    error_show: false,
    item_meta_data: {},
    error_text: ""
  }),
  computed: {
    resourceName: function() {
      return this.$route.params == null ? "xx" : this.$route.params.id;
    }
  },
  created: function() {
    this.loadItems();
  },
  methods: {
    loadItems: function() {
      this.error_show = false;
      this.showLoader = true;
      mj_axios
        .get("/api/list/" + this.resourceName)
        .then(response => {
          if (response.data) {
            this.items = Object.values(response.data.data);
            if (response.data.item_meta_data)
              this.item_meta_data = response.data.item_meta_data;
          }

          this.emptyList = !response.data || this.items.length == 0;
          this.showLoader = false;
        })
        .catch(() => {

          this.showLoader = false;
          this.error_show = true;
          this.error_text = "Errore nel caricamento dati";
        });
    },
    deleteItem: function(item, index) {
      item.deleting = true;
      this.error_show = false;
      const id = item.id || item.cod;
      mj_axios
        .delete(`/api/${mj_string_utils.camelToSnakeCase(this.resourceName)}/${id}`)
        .then(() => {
          this.items.splice(index, 1);
        })
        .catch(() => {

          this.error_show = true;
          this.error_text = "Errore nell'eliminazione..";
        })
        .then(() => {
          item.deleting = false;
        });
    }
  },
  watch: {
    //se cambia il nome della risorsa ricarica gli items
    resourceName: function() {
      this.loadItems();
    }
  }
};
</script>
<template>
  <v-container>
    <v-overlay :value="loading_overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-container>
        <div>Transazione con id: {{ $route.params.id }}</div>
        <v-row>
          <v-col cols='12' md='4'>
            <MjTextField
                label='Quantità Credito'
                v-model='form.amount.val'
                decimal_separator=',' thousand_separator='.' max_fraction_digits='5' max_significant_digits='10'
                locale='it-IT'
                :rules="[rules.required]"
            >
            </MjTextField>
          </v-col>
          <v-col cols='12' md='4'>

            <v-autocomplete
                label='Tipo Movimento'
                v-model='form.type.val'
                item-text="description" item-value="value" :items="this.selects.type" cache-items
                >
            </v-autocomplete>

          </v-col>
          <v-col cols='12' md='4'>
            <v-autocomplete
                label='Utente'
                v-model='form.users_id.val'
                item-text="email" item-value="id" :items="this.selects.users_id" cache-items
                :rules="[rules.required]">
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols='12' md='12'>
            <v-text-field
                label='Description'
                v-model='form.description.val'
                :rules="[rules.max(255)]">
            </v-text-field>
          </v-col>
          <v-col cols='12'>
            <v-textarea
                label='Note'
                v-model='form.note.val'
                rows='2'
            >
            </v-textarea>
          </v-col>
        </v-row>

        <MjSchedaToolbar
            :error_show="error_show"
            :error_text="error_text"
            :edit_not_saved="edit_not_saved"
            :edit_mode="editMode"
            :new_button_visibile="true"
            :restore_item_show="restore_item_show"
            v-on:restore-item-click="restoreItem()"
            v-on:new-item-click="newItem()"
            v-on:save-item-click="saveItem()"
            v-on:go-back-click="goBack()"
        />
      </v-container>
    </v-form>

  </v-container>
</template>

<script>
import schedaMixin from "@/mixins/SchedaMixin.js";
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin.js";
import MjSchedaToolbar from "@/components/MjSchedaToolbar.vue";
import MjTextField from '@/components/form/MjTextField.vue';


export default {
  name: "SchedaCreditTransactionLog",
  mixins: [schedaMixin, ValidationRulesMixin],
  components: {MjSchedaToolbar, MjTextField},
  data: () => ({
    form: {
      amount: {type: 'decimal', val: '', default_val: ''},
      type: {type: 'string', val: '', default_val: '', loadSelect: true, apiUrl: '/api/credit_transaction_types'},
      users_id: {type: 'integer', val: '', default_val: '', loadSelect: true, apiUrl: '/api/user/users'},
      description: {type: 'string', val: '', default_val: ''},
      note: {type: 'text', val: '', default_val: ''},
    },
    apiUrl: "/api/credit_transaction_log"
  }),

  methods: {}
};
</script>

<template>
  <v-container>
    <v-btn class='mr-2' @click="item_id = null; purchase_additional_data = null; price = 100; stripe_dialog_show = true">Paga importo fisso: 100</v-btn>
    <div class="my-4">oppure</div>
    <v-row>
      <v-col><v-text-field v-model="item_id" placeholder="id del prodotto da acquistare"></v-text-field></v-col>
      <v-col>
        <v-btn @click='purchase_additional_data = {"n_partecipants":30,"note":"questa è una nota di prova","team_name":"MERCEDES STARS"};stripe_dialog_show = true'>Paga</v-btn></v-col>

    </v-row>
    <MjStripeDialog :price="price" :purchasing_item_id="item_id" :purchase_additional_data="purchase_additional_data" dialog_title="Test Pagamento Stripe" v-model="stripe_dialog_show" @payment-cancel="stripe_dialog_show=false" @payment-done="stripe_dialog_show=false">
      <div class="mb-6">
      Inserisci i dati della tua carta per procedere al pagamento.
        <v-divider class="mt-6"></v-divider>
        <div>Importo Richiesto <span class="primary--text font-weight-bold">{{price/100}} €</span></div>
      </div>
    </MjStripeDialog>
  </v-container>
</template>

<script>

import MjStripeDialog from "@/components/payment/MjStripeDialog.vue";

export default {
  name: "TestStripeView",
  components: {MjStripeDialog},


  computed: {},
  data: () => ({
    stripe_dialog_show: false,
    price:null,
    item_id: null,
    purchase_additional_data:null,
  }),

  methods: {
    

  },
  watch: {},
};
</script>

<template>
  <v-container>
    <MjTableItems
        ref="table_credit_transaction"
        model-name-prop="CreditTransactionLog"
        resource-collection-name="CreditTransactionLogResourceListCollection"
        sort_by="Data"
        :sort_desc="true"
    >
    </MjTableItems>
  </v-container>
</template>

<script>

import MjTableItems from "@/components/MjTableItems";

export default {
  name: "CreditTransactionLogView",
  mixins: [],
  components: {MjTableItems},
  data: () => ({

  }),

  methods: {

  },
  watch: {

  }
};
</script>

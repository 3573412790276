<template>
  <v-container>
    <v-overlay :value="loading_overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-container>
        <div>Ruolo {{ $route.params.id }}</div>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field v-model="form.id.val" label="Id Ruolo" disabled required></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="form.name.val"
              :rules="[rules.required]"              
              label="Nome Ruolo"
              required
              :disabled="editMode"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-combobox
              v-model="form.permissions.val"
              item-text="name"
              item-value="id"
              :items="selects.permissions"
              deletable-chips
              label="Permessi"
              multiple
              chips
            ></v-combobox>
          </v-col>
        </v-row>
        <MjSchedaToolbar
          :error_show="error_show"
          :error_text="error_text"
          :edit_not_saved="edit_not_saved"
          :edit_mode="editMode"
          :new_button_visibile="true"
          :restore_item_show="restore_item_show"
          v-on:new-item-click="newItem()"
          v-on:save-item-click="saveItem()"
          v-on:goBackClick="goBack()"
          v-on:restoreItemClick="restoreItem()"
        />
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import schedaMixin from "@/mixins/SchedaMixin.js";
import MjSchedaToolbar from "@/components/MjSchedaToolbar.vue";
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin.js";

export default {
  name: "SchedaRoleView",
  mixins: [schedaMixin,ValidationRulesMixin],
  components: { MjSchedaToolbar },
  data: () => ({
    form: {
      id: { type: "integer", val: null},
      name: { type: "string", val: "" },
      permissions: { type: "array", apiUrl:"/api/user/permissions", loadSelect:true, val: [] }
    },
    apiUrl: "/api/user/roles"
  }),

  methods: {}
};
</script>

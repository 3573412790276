<!--
  Questo componente viene usato nelle pagine "public" come login, registrazione, recupera password etc.
  Che non ha senso mostrare se l'utente è già loggato.
  La mappa dei link è fatta in modo che l'utente non capiti in queste pagine.. ma se accade ugualmente
  viene mostrato questo dialog fullscreen e persistent che informa l'utente che è loggato.

  Quest'ultimo può svolgere 3 azioni
  1) click sulla (x) in alto a dx, che genera un back del router
  2) click su Home che mi manda sulla path /Menu
  3) click su Logout, che genera un logout (async) e al termine disabilita il dialog mostrando la pagina che si voleva navigare.

-->
<template>
  <v-dialog v-model="dialog_show" persistent fullscreen>
    <v-card>
      <v-toolbar color="secondary">
        <v-btn icon>
          <v-icon>mdi-information</v-icon>
        </v-btn>
        <v-toolbar-title>Info</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon @click="$router.back()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-title>Hai già fatto il login: {{$store.state.token_obj.email}}</v-card-title>
      <v-card-text>Non puoi visualizzare questa pagina perchè hai già fatto il login.
        <br/>
        Se sei qui per sbaglio vai alla Home<br/>
        Altrimenti effettua il logout per visualizzare la pagina richiesta.
      </v-card-text>
      <v-card-actions>
        <v-btn small class="mx-2 light" outlined @click="$router.replace('/Menu')">
          <v-icon left>mdi-home</v-icon>
          Home
        </v-btn>
        <v-btn small class="mx-2 light" outlined @click="logout">
          <v-icon left>mdi-logout-variant</v-icon>
          Logout
        </v-btn>
      </v-card-actions>
    </v-card>


  </v-dialog>
</template>

<script>
import store from "@/store.js";
export default {
  name: "MjAlreadyLoggedDialog",
  props: {},
  mounted:function(){this.dialog_show = (this.access_token != null)},
  computed: {
    access_token: function () {
      return store.state.token_obj.access_token;
    }
  },
  data() {
    return { dialog_show: false};
  },
  methods: {
    logout: function () {
      this.$store.dispatch("logout").finally(() => {
        this.dialog_show = false;
      });
    },
  },
};
</script>
/*
SCOPO DEL COMPONENTE: MOSTRARE UNA V-COMBOBOX PER AZIONARE UN FILTRO.

INPUT: array con gli items formato come nell'esempio seguente oppure il loadItemUrl per il caricamento degli stessi tramite ajax

:items_prop="[{description:'prova', value:1, icon:'mdi-close'},{description:'prov2', value:2}]"

l'icona è opzionale.

la prop description_as_tooltip indica se mostrare le descrizione dentro i tooltip e toglierle dai button per avere una visione più compatta

OUTPUT: array dei valori selezionati, modalità (and / or), glue (and or tra filtri - diversi mjcombobox)
*/

<template>
  <v-container>
    <v-progress-linear indeterminate v-show="loading"></v-progress-linear>
    <div class="mj_label mb-2">{{ label }}</div>
    <v-row class="align-center">
      <v-col cols="10">
        <v-btn-toggle
            v-model="selected"
            :multiple="multiple"
            @change="emitChange();"

        >
          <v-btn v-for="item in items" :key="item.id" :small="small" :value="item">

            <v-tooltip top v-if="description_as_tooltip !== false">
              <template v-slot:activator="{on,attrs}">
                <v-icon :color="item.color" v-bind="attrs"
                        v-on="on">{{ item.icon }}
                </v-icon>
              </template>
              {{ getDescription(item) }}
            </v-tooltip>
            <span v-else>
          <v-icon :color="item.color">{{ item.icon }}</v-icon>
          {{ getDescription(item) }}
        </span>
          </v-btn>
          <template v-slot:append-outer>
            <v-btn
                fab
                small
                :class="(glue_data === 'AND') ? 'accent caption' : 'primary caption'"
                @click="(glue_data === 'OR') ? glue_data='AND': glue_data='OR';emitChange();"
                v-if="glue_data"
            >{{ glue_data }}
            </v-btn>
          </template>
        </v-btn-toggle>
      </v-col>
      <v-col cols="2">
        <v-btn
            fab
            small
            :class="(glue_data === 'AND') ? 'accent caption' : 'primary caption'"
            @click="(glue_data === 'OR') ? glue_data='AND': glue_data='OR';emitChange();"
            v-if="glue_data"
        >{{ glue_data }}
        </v-btn>
      </v-col>
    </v-row>
    <div class="caption grey--text" v-show="hint.length > 0">
      {{ hint }}
      <a v-show="selected && selected.length > 1" @click.prevent="changeMode">Cambia Modo Ricerca</a>
    </div>
  </v-container>
</template>
<script>

import mj_axios from "@/mj_axios";

export default {
  name: "MjToggleFilter",
  props: {
    label: {},
    multiple: {default: false},
    load_items_url: {},
    items_prop: {type: Array},
    item_description_field: {},
    item_value_field: {},
    filter_name: {},
    small: {},
    description_as_tooltip: {default: false},
    relation_field_name: {},
    glue: {type: String},
    silent_reset: {}, //usata nel watch per capire se il parent chiede un reset
    selected_prop: {}
  },
  computed: {

    hint: function () {
      let hint = "";


      if (this.selected && this.selected.length > 0) {
        hint =
            "Ricerca valida se trovo  " +
            this.selected
                .map((s) => this.getDescription(s))
                .join(this.mode === "AND" ? " e " : " oppure ") +
            ".";
      }


      return hint;
    },
  },
  data: function () {
    return {
      selected: [],
      glue_data: this.glue ? this.glue.toUpperCase() : this.glue,
      mode: "OR",
      items: this.items_prop,
      loading: false,
    };
  },
  created() {

    //dopo il caricamento assegno al v-model selected il valore della prop (serve per inizializzare il componente con un determinato valore passato dal parent)
    this.loadItems().then(this.initSelectedWithProp);

  },
  methods: {
    getDescription: function (item) {
      return (typeof this.item_description_field === "function") ? this.item_description_field(item) : item[this.item_description_field];
    },
    loadItems() {
      return new Promise((resolve, reject) => {
        if (this.load_items_url) {
          this.loading = true;
          mj_axios
              .get(this.load_items_url)
              .then((response) => {
                this.items = response.data;
                resolve();

                // for (let i = 0; i < 44; i++) this.items.push(this.items[0]);
              })
              .catch(() => {
                this.error_text = "Errore nel caricamento dati";
                reject();
              })
              .then(() => {
                this.loading = false;
              });
        } else {
          resolve();
        }
      });
    },
    //logica di inizializzazione tramite prop (passata dal parent) del componente
    initSelectedWithProp(){
      // console.log('created toggle filter ' + this.label + " selected prop " + JSON.stringify(this.selected_prop) + " multiple " + this.multiple);

      //la condizione deve essere != null altrimenti se selected prop è zero non passa.
      if (this.selected_prop != null) {
        if (this.multiple !== false) {
          this.selected = this.items.filter((item) => this.selected_prop.includes(item[this.item_value_field]));
        } else {
          this.selected = this.items.filter((item) => (item[this.item_value_field] === this.selected_prop))[0];
        }

        this.emitChange();
      }


    },

    changeMode: function () {
      if (this.mode == "AND") this.mode = "OR";
      else this.mode = "AND";
      this.emitChange();
      return false;
    },

    emitChange: function () {

      let selected = null;

      if (this.selected && Array.isArray(this.selected)) {
        selected = this.selected.map((item) => item[this.item_value_field]);
      } else if (this.selected) {
        selected = [this.selected[this.item_value_field]];
      }


      const data = {
        name: this.filter_name,
        type: this.mode,
        val: selected,
        glue: this.glue_data ? this.glue_data.toLowerCase() : this.glue_data,
      };
      if (this.relation_field_name) {
        data.relation_field_name = this.relation_field_name;
      }

      this.$emit("change", data);
    },
  }
  ,
  watch: {
    silent_reset: function () {
      this.selected = [];
      //this.emitChange(); non emette cambiamento ci pensa il parent o filtergroup
    }
    ,
  }
  ,
}
;
</script>
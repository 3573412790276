<template>
  <v-container>
    <v-overlay :value="loading_overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-container>
        <div>Entita Esempio {{ $route.params.id }}</div>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              label="descrizione"
              v-model="form.descrizione.val"
              :rules="[rules.required,rules.max(8)]"
            />
          </v-col>
          <v-col cols="12" md="4">
            <MjTextField
              label="numero_int"
              v-model="form.numero_int.val"
              decimal_separator=","
              thousand_separator="."
              max_fraction_digits="0"
              max_significant_digits="21"
              locale="it-IT"
            />
          </v-col>
          <v-col cols="12" md="4">
            <MjTextField
              label="numero_float"
              v-model="form.numero_float.val"
              decimal_separator=","
              thousand_separator="."
              max_fraction_digits="4"
              max_significant_digits="8"
              locale="it-IT"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <MjDateOrTimePicker
              label="data_dal"
              v-model="form.data_dal.val"
              model_format="YYYY-MM-DD"
              mode="date"
              display_format="DD-MM-YYYY"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field label="select" v-model="form.select.val" />
          </v-col>
          <v-col cols="12" md="4">
            <MjTextField
              label="valuta"
              v-model="form.valuta.val"
              decimal_separator=","
              thousand_separator="."
              max_fraction_digits="2"
              max_significant_digits="6"
              locale="it-IT"
              type="money"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <MjDateTimePicker
              label="data_ora"
              v-model="form.data_ora.val"
              time_model_format="HH:mm:ss"
              time_display_format="HH:mm"
              date_model_format="YYYY-MM-DD"
              mode="date"
              date_display_format="DD-MM-YYYY"
            />
          </v-col>
          <v-col cols="12" md="4">
            <MjDateOrTimePicker
              label="ora"
              v-model="form.ora.val"
              model_format="HH:mm:ss"
              mode="time"
              display_format="HH:mm"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-textarea label="text" v-model="form.text.val" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <MjTextField
              label="percentuale"
              v-model="form.percentuale.val"
              decimal_separator=","
              thousand_separator="."
              max_fraction_digits="2"
              max_significant_digits="6"
              locale="it-IT"
              type="percent"
            />
          </v-col>
        </v-row>

        <MjSchedaToolbar
          :error_show="error_show"
          :error_text="error_text"
          :edit_not_saved="edit_not_saved"
          :edit_mode="editMode"
          :new_button_visibile="true"
          v-on:new-item-click="newItem()"
          v-on:save-item-click="saveItem()"
          v-on:goBackClick="goBack()"
        />
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import schedaMixin from "@/mixins/SchedaMixin.js";
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin.js";
import MjSchedaToolbar from "@/components/MjSchedaToolbar.vue";
import MjTextField from "@/components/form/MjTextField.vue";
import MjDateOrTimePicker from "@/components/form/MjDateOrTimePicker.vue";
import MjDateTimePicker from "@/components/form/MjDateTimePicker.vue";

export default {
  name: "SchedaEntitaEsempio",
  mixins: [schedaMixin, ValidationRulesMixin],
  components: {
    MjSchedaToolbar,
    MjTextField,
    MjDateOrTimePicker,
    MjDateTimePicker
  },
  data: () => ({
    form: {
      descrizione: { type: "string", val: "" },
      numero_int: { type: "integer", val: "" },
      numero_float: { type: "decimal", val: "" },
      data_dal: { type: "date", val: "" },
      select: { type: "string", val: "" },
      valuta: { type: "money", val: "" },
      data_ora: { type: "time", val: "" },
      ora: { type: "time", val: "" },
      text: { type: "text", val: "" },
      percentuale: { type: "percent", val: "" }
    },
    apiUrl: "/api/entita_esempios"
  }),

  methods: {}
};
</script>
